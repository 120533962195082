/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
 

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')
require('alpinejs')
require('select2')
require("trix")
require("@rails/actiontext")

import I18n from 'i18n-js'
window.I18n = I18n
import "stylesheets/application"

import "../components/appstore_apk"
import "../components/audio"
import "../components/blog_entry/blog_image_upload"
import "../components/blog_entry/content_drag_and_drop"
import "../components/byod_app/color_preview"
import "../components/byod_app/show_hide_msg"
import "../components/byod_app/logo_upload"
import "../components/byod_app/background_upload"
import "../components/byod_app/font_upload"
import "../components/content_charger_software/upload_area"
import "../components/deployment"
import "../components/device_setting"
import "../components/folder_upload"
import "../components/language"
import  "../components/media_entries_index"
import "../components/media_entry_list_filter"
import  "../components/media_entry_tabs"
import  "../components/media_upload"
import  "../components/mmg_app"
import "../components/mmg_app/logo_upload"
import "../components/mmg_app/background_upload"
import "../components/mmg_app/font_upload"
import "../components/organization"
import "../components/organization_duplication"
import "../components/profile_dropdown"
import "../components/quiz_question"
import "../components/safety_notice/background_upload"
import "../components/safety_notice/confirmation_screen_background_upload"
import "../components/safety_notice/symbol_tabs"
import "../components/safety_notice/icon_upload"
import "../components/safety_notice/no_preview"
import "../components/safety_notice/notice_preview"
import "../components/safety_notice/confirmation_preview"
import "../components/safety_notice/preview_area"
import "../components/safety_notice"
import "../components/safety_notice_settings"
import "../components/station"
import "../components/station/video_audio_sync"
import "../components/station_media_entry_sort"
import "../components/station_group"
import "../components/statistics"
import "../components/status"
import "../components/tours"
import "../components/tour/tour_sort"
import "../components/wifi_network"