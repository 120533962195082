document.addEventListener('turbolinks:load', () => {
    const dropdownSelect = document.getElementById('dropdown-media-type-select')

    const videoTab = document.getElementById('js-video-tab');
    const imageTab = document.getElementById('js-image-tab');
    const audioTab = document.getElementById('js-audio-tab');

    const videoTabSVG = document.getElementById('js-video-tab-svg');
    const imageTabSVG = document.getElementById('js-image-tab-svg');
    const audioTabSVG = document.getElementById('js-audio-tab-svg');

    const videoDiv = document.getElementById('media_entry_videos');
    const imageDiv = document.getElementById('media_entry_image');
    const audioDiv = document.getElementById('media_entry_audio');

    const hiddenMediaTypeForm = document.getElementById('js-hidden-media-type');

    const mediaEntryVideoSubmit = document.getElementById('media_entry_video_submit');
    const mediaEntryAudioSubmit = document.getElementById('media_entry_audio_submit');
    const mediaEntryImageSubmit = document.getElementById('media_entry_image_submit');

    // select initial view to display
    if (hiddenMediaTypeForm != null) {
        console.log("hiddenMediaTypeForm.value: " + hiddenMediaTypeForm.value);
        if (hiddenMediaTypeForm.value === 'video' && videoTab != null) {
            displayVideoTab();
        } else if (hiddenMediaTypeForm.value === 'image' && imageTab != null) {
            displayImageTab();
        } else if (hiddenMediaTypeForm.value === 'audio' && audioTab != null) {
            displayAudioTab();
        }
    }

    // click listener
    if (videoTab != null) {
        videoTab.addEventListener('click', function (event) {
            displayVideoTab();
        });
    }

    if (imageTab != null) {
        imageTab.addEventListener('click', function (event) {
            displayImageTab();
        });
    }

    if (audioTab != null) {
        audioTab.addEventListener('click', function (event) {
            displayAudioTab();
        });
    }

    if (dropdownSelect != null) {
        dropdownSelect.addEventListener('change', function (event) {
            if (this.value === 'video') {
                displayVideoTab();
            } else if (this.value === 'audio') {
                displayAudioTab();
            } else if (this.value === 'image') {
                displayImageTab();
            }
        });
    }


    // display video tab
    function displayVideoTab() {
        if (hiddenMediaTypeForm != null) {
            hiddenMediaTypeForm.value = "video";
        }

        videoTab.className = "tabActive";
        videoTabSVG.classList.add('tabSVGActive');
        videoTabSVG.classList.remove('tabSVG');

        imageTab.className = 'tab';
        imageTabSVG.classList.remove('tabSVGActive');
        imageTabSVG.classList.add('tabSVG');

        audioTab.className = 'tab';
        audioTabSVG.classList.remove('tabSVGActive');
        audioTabSVG.classList.add('tabSVG');

        dropdownSelect.value = 'video';

        videoDiv.style.display = '';
        imageDiv.style.display = 'none';
        audioDiv.style.display = 'none';

        mediaEntryVideoSubmit.classList.remove('hidden');
        mediaEntryAudioSubmit.classList.add('hidden');
        mediaEntryImageSubmit.classList.add('hidden');

        setTimeout(function () {
            document.activeElement.blur();
        }, 300);
    }

    // display image
    function displayImageTab() {
        if (hiddenMediaTypeForm != null) {
            hiddenMediaTypeForm.value = "image";
        }

        imageTab.className = "tabActive";
        imageTabSVG.classList.add('tabSVGActive');
        imageTabSVG.classList.remove('tabSVG')

        videoTab.className = 'tab';
        videoTabSVG.classList.remove('tabSVGActive');
        videoTabSVG.classList.add('tabSVG');

        audioTab.className = 'tab';
        audioTabSVG.classList.remove('tabSVGActive');
        audioTabSVG.classList.add('tabSVG');

        dropdownSelect.value = 'image';

        imageDiv.style.display = '';
        videoDiv.style.display = 'none';
        audioDiv.style.display = 'none';

        mediaEntryVideoSubmit.classList.add('hidden');
        mediaEntryAudioSubmit.classList.add('hidden');
        mediaEntryImageSubmit.classList.remove('hidden');

        setTimeout(function () {
            document.activeElement.blur();
        }, 300);

    }

    // display audio
    function displayAudioTab() {
        if (hiddenMediaTypeForm != null) {
            hiddenMediaTypeForm.value = "audio";
        }

        // tabs
        audioTab.className = "tabActive";
        audioTabSVG.classList.add('tabSVGActive');
        audioTabSVG.classList.remove('tabSVG');

        videoTab.className = 'tab';
        videoTabSVG.classList.remove('tabSVGActive');
        videoTabSVG.classList.add('tabSVG');

        imageTab.className = 'tab';
        imageTabSVG.classList.remove('tabSVGActive');
        imageTabSVG.classList.add('tabSVG');

        dropdownSelect.value = 'audio';

        // audio div
        audioDiv.style.display = '';
        videoDiv.style.display = 'none';
        imageDiv.style.display = 'none';

        mediaEntryVideoSubmit.classList.add('hidden');
        mediaEntryAudioSubmit.classList.remove('hidden');
        mediaEntryImageSubmit.classList.add('hidden');

        setTimeout(function () {
            document.activeElement.blur();
        }, 300);

    }
});
