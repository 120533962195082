document.addEventListener('turbolinks:load', () => {

    const stationAudioControl = document.getElementById('stationAudioControl');
    const audioSelect = document.getElementById('audioSelect');
    const stationAudioSource = document.getElementById('stationAudioSource');

    const videoSelect = document.getElementById('videoSelect');
    const stationVideoControl = document.getElementById('stationVideoControl');
    const stationVideoSource = document.getElementById('stationVideoSource');

    const imageSelect = document.getElementById('imageSelect');
    const stationImageSrc = document.getElementById('stationImageSrc');

    const textSelect = document.getElementById('textSelect');
    const stationSelectedText = document.getElementById('stationSelectedText');

    const websiteSelect = document.getElementById('websiteSelect');
    const stationSelectedWebsite = document.getElementById('stationSelectedWebsite');

    const previewImageType = document.getElementById('previewImageType')
    const previewImageIdViaGallery = document.getElementById('previewImageIdViaGallery');
    const previewImageIdViaStation = document.getElementById('previewImageIdViaStation');
    const previewImageSrc = document.getElementById('previewImageSrc');

    const quizSelect = document.getElementById('quizSelect')

    if (stationAudioControl !== null && audioSelect !== null && stationAudioSource !== null &&
        stationVideoControl !== null && videoSelect !== null && stationVideoSource !== null &&
        imageSelect !== null && stationImageSrc !== null && textSelect !== null && stationSelectedText !== null) {
        init()
    }

    if (previewImageIdViaGallery !== null && previewImageIdViaStation !== null && previewImageSrc !== null &&
        previewImageType != null) {
        initPreviewImageSelect()
    }

    // show selected quiz ajax request
    if (quizSelect !== null) {
        const quizSelect2 = $("#quizSelect").select2();
        quizSelect2.data('select2').$container.addClass("wrap")
        quizSelect2.on('select2:selecting', function(e) {

            const quizStationMediaEntryWarning = document.getElementById('quiz_station_media_entry_warning')
            if (quizStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    quizStationMediaEntryWarning.classList.add("hidden")
                } else {
                    quizStationMediaEntryWarning.classList.remove("hidden")
                    const quizStationMediaEntryWarningMsg = document.getElementById('quiz_station_media_entry_warning_msg')
                    if (quizStationMediaEntryWarningMsg !== null) {
                        quizStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }
            }

            const mediaEntryId = e.params.args.data.id.split(";")[0]
            $.rails.ajax({
                url: `/media_entries/${mediaEntryId}/quiz_questions/station_quiz_overview`,
                type: "get",
                data: "",
            })
        });
        $("#quizSelect").hide()
    }

    function initPreviewImageSelect() {
        // init preview image
        let previewSrc
        const previewImageTypeValue = previewImageType.options[previewImageType.selectedIndex].value
        if (previewImageTypeValue ==="none" || previewImageTypeValue === "first_station_media_entry") {
            previewImageSrc.src = previewImageType.options[previewImageType.selectedIndex].dataset.src
        } else if (previewImageTypeValue === "media_gallery_image") {
            previewImageSrc.src = previewImageIdViaGallery.options[previewImageIdViaGallery.selectedIndex].dataset.src
        } else if (previewImageTypeValue === "any_station_media_entry" ) {
            previewImageSrc.src = previewImageIdViaStation.options[previewImageIdViaStation.selectedIndex].dataset.src
        }

        // change listener for preview type
        previewImageType.addEventListener('change', (e) => {
            if (e.target.value === "none" || e.target.value === "first_station_media_entry") {
                previewImageSrc.src = previewImageType.options[previewImageType.selectedIndex].dataset.src
            } else if (e.target.value === "media_gallery_image") {
                previewImageSrc.src = previewImageIdViaGallery.options[previewImageIdViaGallery.selectedIndex].dataset.src
            } else if (e.target.value === "any_station_media_entry") {
                previewImageSrc.src = previewImageIdViaStation.options[previewImageIdViaStation.selectedIndex].dataset.src
            }
        })

        // update preview image via gallery
        const previewImageSelect2 = $("#previewImageIdViaGallery").select2();
        previewImageSelect2.data('select2').$container.addClass("wrap")
        previewImageSelect2.on('select2:selecting', function(e) {
            previewSrc = e.params.args.data.element.dataset.src
            previewImageSrc.src = previewSrc
        });
        $("#previewImageIdViaGallery").hide()

        // update preview image via station_media_entry
        const previewImageIdViaStationSelect2 = $("#previewImageIdViaStation").select2();
        previewImageIdViaStationSelect2.data('select2').$container.addClass("wrap")
        previewImageIdViaStationSelect2.on('select2:selecting', function(e) {
            previewSrc = e.params.args.data.element.dataset.src
            previewImageSrc.src = previewSrc
        });
        $("#previewImageIdViaStation").hide()
    }

    function init() {

        let audioSrc = audioSelect.value.split(";")[1]
        stationAudioSource.src = audioSrc
        stationAudioControl.load();

        let videoSrc = videoSelect.value.split(";")[1]
        if (videoSrc != null) {
            stationVideoSource.src = videoSrc
            stationVideoControl.load()
        }

        let imageSrc = imageSelect.value.split(";")[1]
        if (typeof imageSrc !== 'undefined' && imageSrc !== null && imageSrc !== "") {
            stationImageSrc.src = imageSrc
        }

        let html = textSelect.value.split(";")[2]
        if (typeof html !== 'undefined' && html !== null && html !== "") {
            stationSelectedText.innerHTML = html
        } else {
            stationSelectedText.innerHTML = I18n.t('js.please_select_a_text')
        }

        let url = websiteSelect.value.split(";")[2]
        if (typeof url !== 'undefined' && url !== null && url !== "") {
            stationSelectedWebsite.innerHTML =  `<a href="${url}" class="border-b border-blue-500 text-blue-500" target="_blank"> ${removeSlashes(url)} </a>`;
        } else {
            stationSelectedWebsite.innerHTML = `<i>${I18n.t('js.please_select_a_website')}</i>`
        }


        const audioSelect2 = $("#audioSelect").select2();
        audioSelect2.data('select2').$container.addClass("wrap")
        audioSelect2.on('select2:selecting', function(e) {
            const audioStationMediaEntryWarning = document.getElementById('audio_station_media_entry_warning')
            if (audioStationMediaEntryWarning !== null) {
                console.log("xxx")
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    audioStationMediaEntryWarning.classList.add("hidden")
                } else {
                    audioStationMediaEntryWarning.classList.remove("hidden")
                    const audioStationMediaEntryWarningMsg = document.getElementById('audio_station_media_entry_warning_msg')
                    if (audioStationMediaEntryWarningMsg !== null) {
                        audioStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }
            }

            audioSrc = e.params.args.data.id.split(";")[1]
            stationAudioSource.src = audioSrc
            stationAudioControl.load();
        });
        $("#audioSelect").hide()

        const videoSelect2 = $("#videoSelect").select2();
        videoSelect2.data('select2').$container.addClass("wrap")
        videoSelect2.on('select2:selecting', function(e) {
            const videoStationMediaEntryWarning = document.getElementById('video_station_media_entry_warning')
            if (videoStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    videoStationMediaEntryWarning.classList.add("hidden")
                } else {
                    videoStationMediaEntryWarning.classList.remove("hidden")
                    const videoStationMediaEntryWarningMsg = document.getElementById('video_station_media_entry_warning_msg')
                    if (videoStationMediaEntryWarningMsg !== null) {
                        videoStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            videoSrc = e.params.args.data.id.split(";")[1]
            stationVideoSource.src = videoSrc
            stationVideoControl.load()
        });
        $("#videoSelect").hide()

        const imageSelect2 = $("#imageSelect").select2();
        imageSelect2.data('select2').$container.addClass("wrap")
        imageSelect2.on('select2:selecting', function(e) {
            const imageStationMediaEntryWarning = document.getElementById('image_station_media_entry_warning')
            if (imageStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    imageStationMediaEntryWarning.classList.add("hidden")
                } else {
                    imageStationMediaEntryWarning.classList.remove("hidden")
                    const imageStationMediaEntryWarningMsg = document.getElementById('image_station_media_entry_warning_msg')
                    if (imageStationMediaEntryWarningMsg !== null) {
                        imageStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            imageSrc = e.params.args.data.id.split(";")[1]
            if (imageSrc !== "") {
                stationImageSrc.src = imageSrc
            }
        });
        $("#imageSelect").hide()

        const textSelect2 = $("#textSelect").select2();
        textSelect2.data('select2').$container.addClass("wrap")
        textSelect2.on('select2:selecting', function(e) {
            const textStationMediaEntryWarning = document.getElementById('text_station_media_entry_warning')
            if (textStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    textStationMediaEntryWarning.classList.add("hidden")
                } else {
                    textStationMediaEntryWarning.classList.remove("hidden")
                    const textStationMediaEntryWarningMsg = document.getElementById('text_station_media_entry_warning_msg')
                    if (textStationMediaEntryWarningMsg !== null) {
                        textStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            html = e.params.args.data.id.split(";")[2]
            stationSelectedText.innerHTML = html
        });
        $("#textSelect").hide()

        const websiteSelect2 = $("#websiteSelect").select2();
        websiteSelect2.data('select2').$container.addClass("wrap")
        websiteSelect2.on('select2:selecting', function(e) {
            const websiteStationMediaEntryWarning = document.getElementById('website_station_media_entry_warning')
            if (websiteStationMediaEntryWarning !== null) {
                if (e.params.args.data.element.dataset.warningMsg === "") {
                    websiteStationMediaEntryWarning.classList.add("hidden")
                } else {
                    websiteStationMediaEntryWarning.classList.remove("hidden")
                    const websiteStationMediaEntryWarningMsg = document.getElementById('website_station_media_entry_warning_msg')
                    if (websiteStationMediaEntryWarningMsg !== null) {
                        websiteStationMediaEntryWarningMsg.innerHTML = e.params.args.data.element.dataset.warningMsg
                    }
                }

            }

            url = e.params.args.data.id.split(";")[2]
            stationSelectedWebsite.innerHTML =  `<a href="${url}" class="border-b border-blue-500 text-blue-500" target="_blank"> ${removeSlashes(url)} </a>`;

        });
        $("#websiteSelect").hide()
    }

    function removeSlashes(url) {
        if (url[0] === "/" && url[1] === "/") {
            return url.substring(2);
        } else {
            return url
        }
    }
});